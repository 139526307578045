<template>
    <div>
        <p class="text">
            {{ $t(text) }}
        </p>
        <div class="screenshot">
            <img class="step-image" src="./assets/step9.png" :alt="$t(text)">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            text: 'faq.firestick.step9.selectTheDownloader'
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
